 
function tooglerInit(){
    $('[data-toggle]').on('click',e=>{
        let toggler = e.currentTarget.dataset.toggle;
        $(toggler).toggleClass('active')
    })

    $('[data-slide-toggle]').on('click',e=>{
        let toggler = e.currentTarget.dataset.slideToggle;
        console.log(toggler)
        $(toggler).slideToggle();
    })
    $('.faq-top').on('click',e=>{
        $(e.target).parents('.faq-item').find('.faq-overflow').slideToggle();
    })

}



function mload(){
    
    console.log('mload')
    
    tooglerInit(); // init data-toggle click
    mresize();

    let slick_option = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll:1,
        swipeToSlide:true,
        appendArrows:$('.team .team-arrows'),
        prevArrow:$('.team .team-arrows .prev'),
        nextArrow:$('.team .team-arrows .next'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }
    $('.team-slider').slick(slick_option);

    let slick_option_first = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll:1,
        swipeToSlide:true,
        // appendArrows:$('.first-slick-arrows'),
        prevArrow:$('.first .prev'),
        nextArrow:$('.first .next'),
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }
    $('.first-slick').slick(slick_option_first);
    AOS.init();
}
function mresize(){
    
}
function mscroll(){

}


    $(window).on('load', mload);
    $(window).on('resize', mresize);
    $(window).on('scroll', mscroll);
 




